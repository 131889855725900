<!--*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===
  - Project: HR APPLICATION
  - Author: Salwa Abuwarda*
  - Author URL: http://linkedin.com/in/salwa-abuwarda-20b06a142
  ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===* -->

<template>
  <div id="data-list">
    <vx-card
      ref="filterCard"
      title="Adding new Policy"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
     <validation-observer ref="observer" v-slot="{ invalid }">
      <form>
        <div class="vx-row">
    <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">    
                <div class="vx-col w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>Title
                  </label>
                      <validation-provider
                    v-slot="{ errors }"
                    name="police_title"
                    rules="required|min:1"
                  >
                    <vs-input
                      class="w-full"
                      icon-pack="feather"
                      icon="icon-chevron-right"
                      icon-no-border
                      v-model="policy_title"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    />
                  </validation-provider>
                </div>
                      <div class="vx-col w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>COMPANY
                  </label>
                  <validation-provider  name="company_id" rules="required |min:1">
                    <v-select
                      label="name"
                      :reduce="item => item.id"
                      :options="companies"
                      :clearable="false"
                      multiple
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="company_id"
                      class="mb-4"
                      placeholder="Company"
                    />
                  
                  </validation-provider>
                </div>
               <div class="vx-col w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Upload File
              </label>
              <vs-upload ref="getFile" @change="getFile" limit="1" />
            </div>
    </div>
        </div>

        <vs-button
          :disabled="invalid"
          type="filled"
          @click="addPolicy()"
          class="mb-2"
          >SUBMIT</vs-button
        >
      </form>
     </validation-observer>
    </vx-card>
  </div>
</template>

<script>
import axios from "@/axios.js";
import vSelect from "vue-select";
import moduleCompaniesManagement from "@/store/get-all-companies/moduleCompaniesManagement.js";
import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});

export default {
   components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      title: null,
      file:null,
      company_id:null,
      policy_file: null,
      policy_title: null,
      fileRequired: false,
    };
  },
   computed: {
    companies() {
      return this.$store.state.company.items;
    },
  },
  methods: {
      getFile() {
      this.policy_file = this.$refs.getFile.filesx[0];
      // console.log(this.policy_file);
    },
    addPolicy() {
      if (!this.policy_file) {
        this.$vs.notify({ color: "danger", title: "File is Required" });
      } else {
      this.$vs.loading();
        const formData = new FormData();
        formData.append("title", this.policy_title);
        formData.append("file", this.policy_file);
         this.company_id.forEach((element, i) => {
        formData.append("companies[" + i + "]", element);
      });
          
      axios
        .post(`${process.env.VUE_APP_BASE_URI}/api/admin/policies`,formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
        })
        .then(response => {
          this.$vs.loading.close();
          if (response.status === 200) {
            this.$vs.notify({
              title: "Success",
              text: "Policy is added",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
             this.$router.push("../GetAll/Policies");
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Policy is not added",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
      }
    },
    goToPolicyList() {
     this.$router.push("/GetAll/Policies");
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(100);
 
    },
      getCompanies() {
      this.$store.dispatch("company/fetchItemsCompanies").then((response) => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
  },
  mounted() {
    this.getCompanies();
  },
  created() {
    if (!moduleCompaniesManagement.isRegistered) {
      this.$store.registerModule("company", moduleCompaniesManagement);
      moduleCompaniesManagement.isRegistered = true;
    }
  },
};
</script>
<style lang="scss">
.err {
  border: #c00000 solid 1px;
}
#data-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
#file {
  padding: 6px !important;
}
.con-input-upload {
  padding: 0;
}
[dir] .con-input-upload {
    padding: 0;
    margin: 0;
}
[dir] .con-img-upload {
  padding: 0;
  margin: 0;
}
[dir="rtl"] .ag-theme-material .ag-rtl {
  text-align: right;
}
[dir="rtl"] .ag-rtl {
  direction: rtl;
}
</style>